<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="110px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="名称" prop="mdName">
            <el-input v-model="form.mdName" placeholder="请输入医疗机构名称" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系人" prop="contPersonName">
            <el-input v-model="form.contPersonName" placeholder="请输入联系人" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式" prop="contTel">
            <el-input v-model="form.contTel" placeholder="请输入联系方式" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="简介" prop="introduction">
            <el-input
              v-model="form.introduction"
              placeholder="请输入简介"
              type="textarea"
              :rows="5"
              maxlength="1500"
              auto-complete="off"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="营业执照图片" prop="businessLicense">
            <upload-img
              :limit="1"
              :upload-lists="businessLicenseList"
              :before-upload="beforeAvatarUpload"
              @uploadChange="uploadSuccess1"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公司从业资质图片" prop="professionalQualification">
            <upload-img
              :limit="1"
              :upload-lists="professionalQualificationList"
              @uploadChange="uploadSuccess2"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="医疗机构地址" prop="city">
            <el-select v-model="form.city" placeholder="请选择所在市" @change="cityChange">
              <el-option v-for="item in cityList" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="area">
            <el-select v-model="form.area" placeholder="请选择所在区" @change="areaChange">
              <el-option v-for="item in areaList" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="street">
            <el-select v-model="form.street" placeholder="请选择所在街道" @change="streetChange">
              <el-option v-for="item in streetList" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label-width="0" prop="address">
            <el-input v-model="form.address" placeholder="请输入详细地址" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg
  },
  data() {
    return {
      cityList: [],
      areaList: [],
      streetList: [],
      natureList: [],
      businessLicenseList: [],
      professionalQualificationList: [],
      titleName: '',
      form: {},
      rules: {
        mdName: [{ required: true, message: '请输入医疗机构名称', trigger: 'blur' }],
        contPersonName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contTel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查联系方式格式' }
        ],
        city: [{ required: true, message: '请选择所在市', trigger: 'change' }],
        area: [{ required: true, message: '请选择所在区', trigger: 'change' }],
        street: [{ required: true, message: '请选择所在街道', trigger: 'change' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        introduction: [{ required: true, message: '请输入简介', trigger: 'blur' }]
      }
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['downloadFile', 'addOrSaveMedicalInst', 'detailMedicalInst', 'getCitysServe', 'getAreasServe', 'getStreetsServe']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 6
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过5MB!')
        return
      }
    },
    getLeaveTime(data) {
      this.form.leaveTime = data
    },
    getFactTime(data) {
      this.form.factTime = data
    },
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加社区医疗机构'
        this.getCitysList('陕西省')
      } else {
        this.titleName = '修改社区医疗机构'
        this.itemData = JSON.parse(this.$route.query.item)
        // 这里调用请求编辑时的详情接口渲染form表单数据
        const params = {
          id: this.itemData.id
        }
        let data = null
        const server = 'detailMedicalInst'
        this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              id: data.id,
              mdName: data.mdName,
              contPersonName: data.contPersonName,
              contTel: data.contTel,
              city: data.city,
              area: data.area,
              street: data.street,
              address: data.address,
              introduction: data.introduction,
              businessLicense: data.businessLicense,
              professionalQualification: data.professionalQualification
            }
            this.getCitysList('陕西省')
            if (data.city) {
              this.getAreasList(data.city)
            }
            if (data.area) {
              this.getStreetsList(data.area)
            }
            if (data.businessLicense) {
              this.getFileDetail1(data.businessLicense)
            }
            if (data.professionalQualification) {
              this.getFileDetail2(data.professionalQualification)
            }
          }
        })
      }
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.businessLicenseList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail2(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.professionalQualificationList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.businessLicense = data.join(',')
    },
    uploadSuccess2(data) {
      this.form.professionalQualification = data.join(',')
    },
    getCitysList(val) {
      const params = {
        parenName: val
      }
      this.getCitysServe(params).then((res) => {
        if (res.code == 200) {
          this.cityList = res.data
        }
      })
    },
    cityChange(val) {
      if (val) {
        this.getAreasList(val)
      }
    },
    // 获取区县
    getAreasList(val) {
      const params = {
        parenName: val
      }
      this.getAreasServe(params).then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetsList(val)
      }
    },
    // 获取街道
    getStreetsList(val) {
      const params = {
        parenName: val
      }
      this.getStreetsServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },
    streetChange(val) { },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          const server = 'addOrSaveMedicalInst'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success'
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;

  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
